body, #root {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100vh;
  background-color:hsl(210deg 29.03% 24.31%)
  /* background-color: var(--amplify-colors-background-primary);; */
}

html {
  height: 100%;
  /* overflow: hidden; */
  /* width: 75%;
  left: 12.5%;
  position: relative; */
}