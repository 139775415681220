@font-face {
  font-family: "Lato";
  src: url("./assets/Lato-Regular.ttf") format("ttf")
}

* {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
  font-family: 'Lato', sans-serif;
}

.formContainer {
  background-color: '#2c3e50';
}

.amplify-card {
  /* margin: .2em; */
  margin-block-end: 1em !important;
  /* margin-top: "2em !important"; */
}

.amplify-button:hover {
  background-color: 'hsl(210, 50%, 10%)';
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
